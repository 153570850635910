exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/AboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-adult-care-page-js": () => import("./../../../src/templates/AdultCarePage.js" /* webpackChunkName: "component---src-templates-adult-care-page-js" */),
  "component---src-templates-baby-care-page-js": () => import("./../../../src/templates/BabyCarePage.js" /* webpackChunkName: "component---src-templates-baby-care-page-js" */),
  "component---src-templates-baby-food-page-js": () => import("./../../../src/templates/BabyFoodPage.js" /* webpackChunkName: "component---src-templates-baby-food-page-js" */),
  "component---src-templates-beauty-care-page-js": () => import("./../../../src/templates/BeautyCarePage.js" /* webpackChunkName: "component---src-templates-beauty-care-page-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/CareerPage.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-express-clinic-page-js": () => import("./../../../src/templates/ExpressClinicPage.js" /* webpackChunkName: "component---src-templates-express-clinic-page-js" */),
  "component---src-templates-feminine-care-page-js": () => import("./../../../src/templates/FeminineCarePage.js" /* webpackChunkName: "component---src-templates-feminine-care-page-js" */),
  "component---src-templates-infant-formula-page-js": () => import("./../../../src/templates/InfantFormulaPage.js" /* webpackChunkName: "component---src-templates-infant-formula-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-location-page-js": () => import("./../../../src/templates/LocationPage.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-medicine-page-js": () => import("./../../../src/templates/MedicinePage.js" /* webpackChunkName: "component---src-templates-medicine-page-js" */),
  "component---src-templates-medicines-page-js": () => import("./../../../src/templates/MedicinesPage.js" /* webpackChunkName: "component---src-templates-medicines-page-js" */),
  "component---src-templates-selectmil-page-js": () => import("./../../../src/templates/SelectmilPage.js" /* webpackChunkName: "component---src-templates-selectmil-page-js" */),
  "component---src-templates-vitamins-page-js": () => import("./../../../src/templates/VitaminsPage.js" /* webpackChunkName: "component---src-templates-vitamins-page-js" */)
}

